a {
  text-decoration: none !important;
  color: inherit;
}

a p {
  color: black;
}

.slider {
  margin-left: 12px;
}

.navbar-brand .logo {
  max-width: 70px;
}

.slider-banner .cover .header-content {
  text-align: left;
}

.ptb {
  padding-top: 60px;
}

.cover {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-hed01 {
  margin: 20px;
}

.btn {
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  text-align: center;
}

.comon-matchbn {
  padding: 0 10px;
}

.big-ret .nes-text h5 {
  color: black;
}

.cul-div h6,
.cup-details h5,
.news h5,
.news h6,
.big-ret .nes-text h6 {
  color: black;
}

.footer_links {
  text-align: center;
}

.footer_links a {
  color: #ddd;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  margin-right: 16px;
}

.logo_footer img {
  max-width: 100px;
}

.logo_footer {
  text-align: center;
  margin-bottom: 20px;
}

.react-multiple-carousel__arrow--right,
.react-multiple-carousel__arrow--left {
  display: none;
}

.comon-btn {
  background-color: #535cfb;
  padding: 5px;
  margin-left: 10px;
  border-radius: 4px;
  border: none;
  color: #fff;
}

.subscribe {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subscribe ul {
  margin-bottom: 0px !important;
  padding-left: 0px !important;
}

.sub_input {}




@media only screen and (max-width: 768px) {
  .navbar-brand .logo {
    max-width: 50px;
  }

  header .navbar {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .left-history,
  .comon-fild-ads1 figure {
    width: 100%;
  }
}


.about-club-top {
  display: inline-block;
  width: 100%;
  position: relative;
  margin-top: 40px;
}

.contact_form {
  /* background-color:whitesmoke */
}

.img-oudi img {
  height: 200px;
  width: 100%;
}

.Address,
.mail,
.phone {
  display: flex;
  align-items: center;

}

.Address h5 {
  margin-right: 8px;
}

.mail h5 {
  margin-right: 8px;
}

.phone h5 {
  margin-right: 8px;
}

.comon-heading {
  font-weight: bold;
}

.nav-item {
  margin: 0 8px;
}

.navbar-light .navbar-nav .nav-link {
  font-size: 14px;
  letter-spacing: 0.5px;
}

.no_data_available {
  display: flex;
  align-items: center;
  justify-content: center;
}

.no_data_available h3 {
  margin-bottom: 0;
  padding: 30px 0;
  font-size: 20px;
}

.SubBlog_main {
  display: flex;
  /* border-top: 1px solid #9e9e9e; */
  border-bottom: 1px solid #d5d5d5;
  padding: 10px;
}

.SubBlog_main:nth-child(1) {
  border-top: 1px solid #d5d5d5 !important;
}

.SubBlog_main .time {
  font-size: 14px;
  min-width: 80px;
  margin-right: 10px;
  margin-top: 10px;
  text-wrap: nowrap;
}

.SubBlog_main_inner {
  border-left: 1px solid #d5d5d5;
  padding-left: 10px;
  position: relative;
}



.SubBlog_main_inner::before {
  content: '';
  position: absolute;
  top: 10px;
  left: -5px;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
}

.live_blog_status {
  background-color: rgb(224, 254, 224);
  max-width: 150px;
  padding: 3px 16px;
  margin: auto;
  text-align: center;
  border-radius: 20px;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
  border: 1px solid #00aa5a;
  color: #00aa5a;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.live_info_circle {
  width: 6px;
  height: 6px;
  background: #00aa5a;
  border-radius: 50%;


}

.live_info_blinker {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: rgb(0 170 90 / 40%);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
}

.nav-item img {
  max-width: 24px;
  margin-right: 7px;
  margin-top: -3px;
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.navbar-brand .logo {
  max-width: 60px;
}

.navbar-brand div {
  margin-left: 10px;
}

.navbar-brand h1 {
  margin-bottom: 0;
  padding-bottom: 0;
  font-size: 30px;
  font-weight: 800;
}

.navbar-brand p {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
}

.header_d_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header_form .d_flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.app_download_btn_mobile {
  display: none;
}

.header_form .form-control {
  width: 60%;
}

.header_form {
  display: flex;
  justify-content: flex-end;
}

.bottom_banner {
  position: fixed;
  bottom: 0;
  /* z-index: 99; */
}

.bottom_banner figure {
  margin: 0;
}

@media screen and (max-width: 991px) {
  .subscribe {
    flex-direction: column;
  }

  .subscribe ul,
  .header_download_btn {
    margin-bottom: 10px !important;
  }

  .app_download_btn_mobile {
    display: block;
  }

  .app_download_btn_desktop {
    display: none;
  }

  .header_form .form-control {
    width: 100%;
  }

  .header_form {
    justify-content: center;
  }

  .newsli-div::before {
    height: 230px;
  }

  .main-hed01 {
    padding: 0;
  }
}

@media screen and (max-width: 500px) {
  .navbar-brand .logo {
    max-width: 40px;
  }

  .navbar-brand h1 {
    font-size: 20px;
  }

  .navbar-brand p {
    font-size: 8px;
  }

  .big-ret .nes-text h5 {
    font-size: 30px;
  }

  .app_download_btn_mobile,
  .comon-btn {
    font-size: 14px;
  }

  .main-hed01 {
    font-size: 26px;
  }

  .top-socli li .btn {
    margin: 0 1px;
  }

  .comon-btn {
    margin-left: 0;
    font-size: 12px;
  }
}